import React, { useState } from "react";
import axios from "axios";
import { API_URL } from "../../constants/ApiLink";
import { Button } from "@material-ui/core";
import AlertMessage from "../AlertMessage/AlertMessage";
import { CssTextField, useStyles } from "../../components/FormTheme/FormTheme";
import TextField from '@material-ui/core/TextField';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/sv";

const md5 = require("md5");
const sha512 = require("sha512");

const SuperAdminEditUser = (props) => {
  const [locale, setLocale] = useState("sv");

  const handleBirthDate = (date) => {
    const birth = moment(date).isValid()
      ? moment(date).format("YYYY-MM-DD")
      : "";
    setBirthDate(birth);
  };

  const [username, setUserName] = useState(props.rowData.username);
  const [password, setPassword] = useState("");
  const [first_name, setFirstName] = useState(props.rowData.first_name);
  const [last_name, setLastName] = useState(props.rowData.last_name);
  const [city, setCity] = useState(props.rowData.adress);
  const [anställningsnummer, setEmployeeNumber] = useState(
    props.rowData.anställningsnummer
  );
  const [birth_date, setBirthDate] = useState(
    props.rowData.birth_date.slice(0, 10)
  );
  const [comments, setComments] = useState(props.rowData.comments);
  const [keywords, setKeyWords] = useState(props.rowData.keywords);
  const [telefonnummer, setPhoneNumber] = useState(props.rowData.telefonnummer);
  const [title, setTitle] = useState(props.rowData.title);
  const [userId, setUserId] = useState(props.rowData.user_id);
  const [status, setStatusBase] = useState("");

  const classes = useStyles();

  const updateUser = (e) => {
    e.preventDefault();
    let secretKey = "timtravel1.0";
    let hasher = sha512.hmac(secretKey);
    let passwordHash = hasher.finalize(password);
    let hashedPassword = md5(passwordHash.toString("hex"));

    // IF SUPERUSER SELECT WHICH COMPANY
    // IF COMPANY_ADMIN, GET COMPANY ...

    let editedUserObject = {
      userId: userId,
      active: 1,
      username: username,
      firstName: first_name,
      lastName: last_name,
      birthDate: birth_date,
      title: title,
      anstalning: anställningsnummer,
      telefon: telefonnummer,
      adress: city,
      keywords: keywords,
      comments: comments,
      userType: props.rowData.user_type_id,
      compnay: props.rowData.company,
      gender: props.rowData.gender,
      zip: props.rowData.zip_code,
      previousPosition: props.rowData.previousPosition,
      cvdoc: props.rowData.cv_doc_name,
    };

    let stringifiedEditedUserObject = JSON.stringify(editedUserObject);

    axios
      .post(
        `${API_URL}updateUser`,
        {
          userObject: stringifiedEditedUserObject,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setStatusBase({
            msg: `${first_name} ${last_name} uppdaterad`,
            key: Math.random(),
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
        if(error.response.data.status === false && error.response.data.message === "Parallel login"){
          localStorage.clear();
          localStorage.setItem('parallel_login', true);
          document.location.href="/";
        }
      });
  };

  return (
    <>
      <form onSubmit={updateUser}>
        <div className="third-section">
          <div className="form-element">
            <CssTextField
              id="first_name"
              name="first_name"
              label="Förnamn"
              type="text"
              variant="outlined"
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
            />
          </div>
          <div className="form-element">
            <CssTextField
              id="last_name"
              name="last_name"
              label="Efternamn"
              type="text"
              variant="outlined"
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
            />
          </div>
        </div>
        <div className="fifty-section">
          <div className="form-element">
            <CssTextField
              id="username"
              name="username"
              label="E-postadress"
              type="email"
              variant="outlined"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              fullWidth
            />
          </div>
          {/* <div className="form-element">
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={MomentUtils}
              locale={locale}
            >
              <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Födelsedatum"
                className="date-picker"
                format="YYYY-MM-DD"
                value={birth_date}
                InputAdornmentProps={{ position: "start" }}
                onChange={handleBirthDate}
              />
            </MuiPickersUtilsProvider>
          </div> */}
           <div className="form-element datetime-element greyed">
                  <TextField
                    id="date"
                    label="Födelsedatum"
                    type="date"
                    fullWidth
                    value={birth_date}
                    onChange={e => handleBirthDate(e.target.value)}
                    className={classes.textField}
                    inputProps={{
                      min: '1920-01-01',
                      max: '9999-12-12'
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
        </div>
        <div className="fifty-section">
          <div className="form-element">
            <CssTextField
              id="title"
              name="title"
              label="Yrke"
              type="text"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
            />
          </div>
          <div className="form-element">
            <CssTextField
              id="keywords"
              name="keywords"
              label="Nyckelord"
              type="text"
              variant="outlined"
              value={keywords}
              onChange={(e) => setKeyWords(e.target.value)}
              fullWidth
            />
          </div>
        </div>
        <div className="third-section">
          <div className="form-element">
            <CssTextField
              id="anställningsnummer"
              name="anställningsnummer"
              label="Anställningsnummer"
              type="number"
              variant="outlined"
              value={anställningsnummer}
              onChange={(e) => setEmployeeNumber(e.target.value)}
              fullWidth
            />
          </div>

          <div className="form-element">
            <CssTextField
              id="telefonnummer"
              name="telefonnummer"
              label="Telefonnummer"
              type="phone"
              variant="outlined"
              value={telefonnummer}
              onChange={(e) => setPhoneNumber(e.target.value)}
              fullWidth
            />
          </div>
          <div className="form-element">
            <CssTextField
              id="city"
              name="city"
              label="Kommun"
              type="text"
              variant="outlined"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              fullWidth
            />
          </div>
        </div>
        <div className="full-section">
          <div className="form-element">
            <CssTextField
              id="comments"
              name="comments"
              label="Kommentarer"
              type="text"
              variant="outlined"
              value={comments}
              multiline
              rows={3}
              onChange={(e) => setComments(e.target.value)}
              fullWidth
            />
          </div>
        </div>
        <div className="form-actions-right-modal">
          <Button type="submit" size="large" className="primary-btn">
            Spara
          </Button>
        </div>
      </form>
      {status ? <AlertMessage key={status.key} message={status.msg} /> : null}
    </>
  );
};

export default SuperAdminEditUser;
