import React from 'react';
import { Paper } from "@material-ui/core";

const OfferSuccessPage = props => {
  return(
    <div className="login-wrapper">
      <Paper elevation={2} className="content-card login-container">
        <h1 className="logo">TimTravel</h1>
       <p>Tack!</p>
       <p>Du har blivit inbokad på passet.</p>
      </Paper>
    </div>

  )
}

export default OfferSuccessPage;