import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import AlertMessage from '../AlertMessage/AlertMessage';
import axios from 'axios';
import { API_URL } from '../../constants/ApiLink';
import './deleteusermodal.scss';

export default function DeleteUserModal(props) {

  const { user_id, first_name, last_name, users, setUsers } = props.rowData;

  const [open, setOpen] = useState(false);
  const [status, setStatusBase] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  const deleteUser = () => {

    axios
    .post(
      `${API_URL}deleteUser`,
      {
        userId: user_id
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        setOpen(false);
        setStatusBase({ msg: `${first_name} ${last_name} borttagen`, key: Math.random() });
        handleClose();
      }
    })
    .catch((error) => {
      console.log("Error", error);
      if(error.response.data.status === false && error.response.data.message === "Parallel login"){
        localStorage.clear();
        localStorage.setItem('parallel_login', true);
        document.location.href="/";
      }
    });
};

  return (
    <>
      <span className="delete-user-bin" onClick={handleClickOpen}>
        <DeleteOutline/>
      </span>
      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className="delete-user-modal"
      >
        <MuiDialogTitle disableTypography>
          <div className="delete-user-dialog-title">
            <h2>Radera användare</h2>
            {handleClose ? (
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </div>
        </MuiDialogTitle>
        <DialogContent>
         <p className="delete-confirm-text">
            Vill du radera användaren {first_name} {last_name}?
           </p>
         <div className="form-actions-right delete-user-action">
          <Button type="submit" size="large" onClick={handleClose} className="no-btn">
            AVBRYT
          </Button>
          <div className="btn-spacer"></div>
         <Button type="submit" size="large" className="warning-btn" onClick={deleteUser}>
            RADERA
          </Button>
        </div>
        </DialogContent>
      </Dialog>
      {status ? <AlertMessage key={status.key} message={status.msg} /> : null}
    </>
  );
}
