import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { API_URL } from "../../constants/ApiLink";
import * as moment from "moment";
import { userRole } from "../../helpers/userdetails";
import AlertMessage from "../../components/AlertMessage/AlertMessage";
import axios from "axios";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/sv";
import { DateTimePicker } from "@material-ui/pickers";
import { CssTextField, useStyles } from "../FormTheme/FormTheme";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const CreateOffer = (props) => {

  const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }));

  const [locale, setLocale] = useState("sv");

  const [careUnits, setCareUnits] = useState([]);
  const [filteredCareUnits, setFilteredCareUnits] = useState([]);
  const [bonusTypes, setBonusTypes] = useState([]);
  const [status, setStatusBase] = useState("");
  const [minDate, setMinDate] = useState(moment(new Date()).format("YYYY-MM-DDTHH:MM"));


  let now = moment();
  let dateNow = now.format("YYYY-MM-DDTHH:MM");

  const [careUnit, setCareUnit] = useState("");
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState(dateNow);
  const [endTime, setEndTime] = useState(dateNow);
  const [express, setExpress] = useState("");
  const [bonusType, setBonusType] = useState("");
  const [sum, setSum] = useState("");
  const [message, setMessage] = useState("");
  const [shiftStart, setShiftStart] = useState(dateNow);
  const [shiftEnd, setShiftEnd] = useState(dateNow);
  const [currCompanyId, setCurrCompanyId] = useState(0)
  const [companies, setCompanies] = useState([]);
  const classes = useStyles();

  let currUserData = JSON.parse(localStorage.getItem('tokens'));
  let currUserCompanyId = currUserData.data.company_id;
  let currUserId = currUserData.data.id;

  let shift_on_company = currUserData.data.user_type !== '1' ? currUserCompanyId : currCompanyId;

  useEffect(() => {
    axios
      .get(`${API_URL}getEnhets`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setCareUnits(response.data.data);
          if(currUserData.data.user_type === '1'){
          if(!currCompanyId || currCompanyId === 0){
            setFilteredCareUnits(response.data.data);
          }
        }else{
          setFilteredCareUnits(response.data.data);
        }
        }
      })
      .catch((error) => {
        console.log("Error", error);
        if(error.response.data.status === false && error.response.data.message === "Parallel login"){
          localStorage.clear();
          localStorage.setItem('parallel_login', true);
          document.location.href="/";
        }
      });
  }, [currCompanyId]);

  useEffect(() => {

    axios.get(`${API_URL}getCompanies`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      if (response.status === 200) {
        let all_companies_without_tt = response.data.data.filter(company => company.id !== 1);
        setCompanies(all_companies_without_tt);
      }
    })
    .catch((error) => {
      console.log("Error", error);
      if(error.response.data.status === false && error.response.data.message === "Parallel login"){
        localStorage.clear();
        localStorage.setItem('parallel_login', true);
        document.location.href="/";
      }
    });

  }, [])

  useEffect(() => {
    axios
      .get(`${API_URL}/getBonusTypes`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setBonusTypes(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error", error);
        if(error.response.data.status === false && error.response.data.message === "Parallel login"){
          localStorage.clear();
          localStorage.setItem('parallel_login', true);
          document.location.href="/";
        }
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    let start_time = new Date(startTime);
    let end_time = new Date(endTime);
    let pass_start = new Date(shiftStart);
    let pass_end = new Date(shiftEnd);

    
    if(end_time > start_time && pass_end > pass_start){
      
      let offerObj = {
        message: message || " ",
        start_time: moment(startTime).format("YYYY-MM-DD HH:mm"),
        end_time: moment(endTime).format("YYYY-MM-DD HH:mm"),
        vard_enhet: careUnit,
        express: express,
        shift_start: moment(shiftStart).format("YYYY-MM-DD HH:mm"),
        shift_end: moment(shiftEnd).format("YYYY-MM-DD HH:mm"),
        bonus_type: bonusType,
        bonus: sum,
        date: date,
        company_id: shift_on_company,
        offer_creator: currUserId
      };
  
      let stringifiedOfferObject = JSON.stringify(offerObj);

    axios
      .post(
        `${API_URL}addOffer`,
        {
          offerObj: stringifiedOfferObject,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.data.success && response.data.message === "Offer Added") {
          let userIds = props.selectedUsers;

          let joinedUserIds = userIds.join();

          let smsObj = {
            offerId: response.data.offer_id,
            userIds: joinedUserIds,
          };

          let stringifiedSmsObj = JSON.stringify(smsObj);

          axios
            .post(
              `${API_URL}sendOfferMsg`,
              {
                userIdsObj: stringifiedSmsObj,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.status === 200) {
                setStatusBase({
                  sev: 'success',
                  msg: response.data.message,
                  key: Math.random(),
                });

                setTimeout(() => props.setOpen(false), 5000);

              }
            })
            .catch((error) => {
              console.log("Error", error);
              setStatusBase({
                sev: 'error',
                msg: `Något gick snett, försök igen.`,
                key: Math.random(),
              });
              if(error.response.data.status === false && error.response.data.message === "Parallel login"){
                localStorage.clear();
                localStorage.setItem('parallel_login', true);
                document.location.href="/";
              }
            });
        }
      }).catch((error) => {
        console.log("Error", error);
        setStatusBase({
          sev: 'error',
          msg: `Erbjudandet skapades inte. Försök igen.`,
          key: Math.random(),
        });
        if(error.response.data.status === false && error.response.data.message === "Parallel login"){
          localStorage.clear();
          localStorage.setItem('parallel_login', true);
          document.location.href="/";
        }
      });
    }else{
      
      if(end_time < start_time){
      setStatusBase({
        sev: 'error',
        msg: `Erbjudandet kan inte sluta gälla innan det har börjat.`,
        key: Math.random(),
      });
    }
    else if(pass_end < pass_start){
      setStatusBase({
        sev: 'error',
        msg: `Passet kan inte sluta innan det har börjat.`,
        key: Math.random(),
      });
    }else{
      setStatusBase({
        sev: 'error',
        msg: `Något gick snett, försök igen.`,
        key: Math.random(),
      });
    }
   }
  };

  const setCurrCompanyIdFunc = (id) => {
    setCurrCompanyId(id);
    let filteredCareUnits = careUnits.filter(careunit => careunit.company_id == id);
    setFilteredCareUnits(careUnits.filter(careunit => careunit.company_id == id));
  }

  const handleStartTime = (date) => {
    setStartTime(date);
  };

  const handleEndTime = (date) => {
    setEndTime(date)
  };

  const handleShiftStart = (date) => {
    setShiftStart(date);
  };

  const handleShiftEnd = (date) => {
    setShiftEnd(date);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="create-offer-form">
        {currUserData.data.user_type == "1"
        ? <div className="full-section">
          <div className="form-element">
          <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel htmlFor="outlined-age-native-simple">
                Företag
              </InputLabel>
              <Select
                native
                onChange={e => setCurrCompanyIdFunc(e.target.value)}
                label="Företag"
                variant="outlined"
                value={currCompanyId}
                disableUnderline={true}
                required
                inputProps={{
                  name: "company",
                  id: "company",
                }}
              >
                <option></option>
                {companies.map((company) => {
                  return (
                    <option key={company.id} value={company.id}>
                      {company.company_name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div> : null}
        <div className="fifty-section">
          <div className="form-element">
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel htmlFor="outlined-age-native-simple">
                Vårdenhet
              </InputLabel>
              <Select
                native
                onChange={(e) => setCareUnit(e.target.value)}
                label="Vårdenhet"
                variant="outlined"
                value={careUnit}
                disableUnderline={true}
                required
                inputProps={{
                  name: "careunit",
                  id: "careunit",
                }}
              >
                <option></option>
                {filteredCareUnits.map((careUnit) => {
                  return (
                    <option key={careUnit.id} value={careUnit.id}>
                      {careUnit.enhet_name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="form-element">
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel htmlFor="outlined-age-native-simple">
                Välj typ
              </InputLabel>
              <Select
                native
                onChange={(e) => setExpress(e.target.value)}
                label="Välj typ"
                variant="outlined"
                required
                value={express}
                inputProps={{
                  name: "type",
                  id: "type",
                }}
              >
                {bonusTypes.map((bonusType) => {
                  return (
                    <option value={bonusType.id} key={bonusType.id}>
                      {bonusType.bonus_name !== "empty"
                        ? bonusType.bonus_name
                        : ""}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="fifty-section">
          <div className="form-element datetime-element">
              <TextField
              id="datetime-local"
              label="Erbjudande fr.o.m"
              type="datetime-local"
              required
              className={classes.textField}
              defaultValue={dateNow}
              onChange={e => handleStartTime(e.target.value)}
              value={startTime}
              inputProps={{
                min: minDate
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="form-element datetime-element">
             <TextField
              id="datetime-local"
              label="Erbjudande t.o.m"
              type="datetime-local"
              required
              className={classes.textField}
              defaultValue={dateNow}
              onChange={e => handleEndTime(e.target.value)}
              value={endTime}
              inputProps={{
                min: minDate
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
        {express === "1" ? (
          <div className="fifty-section">
            <div className="form-element">
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel htmlFor="outlined-age-native-simple">
                  Bonustyp
                </InputLabel>
                <Select
                  native
                  onChange={(e) => setBonusType(e.target.value)}
                  label="Bonustyp"
                  variant="outlined"
                  required
                  value={bonusType}
                  inputProps={{
                    name: "bonus",
                    id: "bonus",
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={"0"}>Bonus per timme</option>
                  <option value={"1"}>Fast bonus</option>
                </Select>
              </FormControl>
            </div>
            <div className="form-element">
              <CssTextField
                id="sum"
                name="sum"
                label="Summa (kr)"
                type="text"
                variant="outlined"
                value={sum}
                required
                onChange={(e) => setSum(e.target.value)}
                fullWidth
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="fifty-section">
          <div className="form-element datetime-element">
            <TextField
              id="datetime-local"
              label="Passet startar"
              type="datetime-local"
              required
              className={classes.textField}
              defaultValue={dateNow}
              onChange={e => handleShiftStart(e.target.value)}
              value={shiftStart}
              inputProps={{
                min: minDate
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="form-element datetime-element">
            <TextField
              id="datetime-local"
              label="Passet slutar"
              type="datetime-local"
              required
              className={classes.textField}
              defaultValue={dateNow}
              onChange={e => handleShiftEnd(e.target.value)}
              value={shiftEnd}
              inputProps={{
                min: minDate
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
        <div className="full-section">
          <div className="form-element">
            <CssTextField
              id="message"
              name="text"
              label="Meddelande"
              type="text"
              variant="outlined"
              value={message}
              multiline
              rows={4}
              onChange={(e) => setMessage(e.target.value)}
              fullWidth
            />
          </div>
        </div>
        <div className="form-actions-right create-offer-action">
          <Button type="submit" size="large" className="primary-btn">
            Skicka erbjudande
          </Button>
        </div>
      </form>
      {status ? <AlertMessage key={status.key} message={status.msg} sev={status.sev} /> : null}
    </>
  );
};

export default CreateOffer;