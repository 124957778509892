import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { API_URL } from "../constants/ApiLink";
import axios from "axios";
import NavBar from "../components/NavBar/NavBar";
import AlertMessage from '../components/AlertMessage/AlertMessage';
import { Container, Paper, Button } from "@material-ui/core";
import {
  CssTextField,
  CSSSelect,
  useStyles,
} from "../components/FormTheme/FormTheme";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

const AddCareUnitPage = props => {

  const userObj = JSON.parse(localStorage.getItem('tokens'));
  const userObjInfo = userObj['data'];
  const { company_id, user_type } = userObjInfo;

  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState("");
  const [remarks, setRemarks] = useState('');
  const [careUnit, setCareUnit] = useState("");
  const [userCompany, setUserCompany] = useState([]);
  const [status, setStatusBase] = useState("");
  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("user_type")
  );

  useEffect(() => {
    axios.get(`${API_URL}getCompanies`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const allCompanies = response.data.data;
          allCompanies.unshift({});
          setCompanies(allCompanies);
        }
      })
      .catch((error) => {
        console.log("Error", error);
        if(error.response.data.status === false && error.response.data.message === "Parallel login"){
          localStorage.clear();
          localStorage.setItem('parallel_login', true);
          document.location.href="/";
        }
      });
    }, []);

  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();

    let careUnitData = { 
      enhet_name: careUnit,
      remarks: remarks,
      company_id: user_type !== "1" ? company_id : company,
    };

    let careUnitObject = JSON.stringify(careUnitData);

    axios
      .post(
        `${API_URL}addEnhet`,
        {
          enhetObj: careUnitObject,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setStatusBase({ msg: `${careUnit} tillagd`, key: Math.random() });
          setCompany('');
          setCareUnit('');
          setRemarks('');
        }
      })
      .catch((error) => {
        console.log("Error", error);
        setStatusBase({ sev: 'error', msg: `${careUnit} finns redan i databasen`, key: Math.random() });
        if(error.response.data.status === false && error.response.data.message === "Parallel login"){
          localStorage.clear();
          localStorage.setItem('parallel_login', true);
          document.location.href="/";
        }
      });
  };

  if (currentUser !== "1" && currentUser !== "2") {
    return <Redirect to={{ pathname: "/404" }} />;
  }

  return (
    <Container>
      <NavBar title="TimTravel" />
      <main className="menu-margin">
        <form onSubmit={handleSubmit}>
          <div className="content">
            <Paper elevation={2} className="content-card">
              <h2>Lägg till vårdenhet</h2>
              {currentUser !== "1" ? (
                <div className="fifty-section">
                  <div className="form-element">
                    <CssTextField
                      id="careUnit"
                      name="careUnit"
                      label="Vårdenhet"
                      type="text"
                      variant="outlined"
                      required
                      value={careUnit}
                      onChange={(e) => setCareUnit(e.target.value)}
                      fullWidth
                    />
                  </div>
                  <div className="form-element">
                    <CssTextField
                      id="remarks"
                      name="remarks"
                      label="Kommun"
                      type="text"
                      variant="outlined"
                      required
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                      fullWidth
                    />
                  </div>
                </div>
              ) : (<><div className="full-section">
                  <div className="form-element">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel htmlFor="outlined-age-native-simple">
                        Företag
                      </InputLabel>
                      <CSSSelect
                        native
                        onChange={(e) => setCompany(e.target.value)}
                        label="Företag"
                        required
                        variant="outlined"
                        inputProps={{
                          name: "company",
                          id: "company",
                        }}
                      >
                        {companies.map(company => {
                        return <option key={company.id} value={company.id}>{company.company_name}</option>
                        })}
                      </CSSSelect>
                    </FormControl>
                  </div>
                </div>
                <div className="fifty-section">
                  <div className="form-element">
                    <CssTextField
                      id="careUnit"
                      name="careUnit"
                      label="Vårdenhet"
                      type="text"
                      variant="outlined"
                      required
                      value={careUnit}
                      onChange={(e) => setCareUnit(e.target.value)}
                      fullWidth
                    />
                  </div>
                  <div className="form-element">
                    <CssTextField
                      id="remarks"
                      name="remarks"
                      label="Kommun"
                      type="text"
                      variant="outlined"
                      required
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                      fullWidth
                    />
                  </div>
                </div></>
              )}
              <div className="form-actions-right">
                <Button type="submit" size="large" className="primary-btn">
                  Lägg till vårdenhet
                </Button>
              </div>
            </Paper>
          </div>
        </form>
      </main>
      {status ? <AlertMessage key={status.key} message={status.msg} sev={status.sev} /> : null}
    </Container>
  );
};
export default AddCareUnitPage;
