import React from "react";
import SuperAdminNav from "./SuperAdminNav";
import CompanyAdminNav from "./CompanyAdminNav";
import BookerNav from "./BookerNav";

const NavBar = (props) => {
  const userObj = JSON.parse(localStorage.getItem("tokens"));
  const userData = (userObj && userObj.data && typeof(userObj['data']) !== 'undefined' && (typeof(userObj['data'] != null))) ? userObj["data"]:[];

  const { user_type } = userData;

    switch(user_type){
      case "1": 
        return <SuperAdminNav title="TimTravel" account="SUPERADMIN" />;
      case "2": 
        return <CompanyAdminNav title="TimTravel" account="COMPANYADMIN" />;
      case "3": 
        return <BookerNav title="TimTravel" account="BOOKER" />;
      default: 
        return null;
    }

};

export default NavBar;
