import React, { useState, useEffect } from "react";
// import Checkbox from "@material-ui/core/Checkbox";
import NavBar from "../NavBar/NavBar";
import Spinner from "../Spinner/Spinner";
import axios from "axios";
import { API_URL } from "../../constants/ApiLink";
import { Redirect } from "react-router-dom";
import { AllCheckerCheckbox, Checkbox, CheckboxGroup } from '@createnl/grouped-checkboxes';
import {
  CSSSelect,
  CssTextField,
  useStyles,
} from "../../components/FormTheme/FormTheme";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import OfferModal from "../Offer/OfferModal/OfferModal";
import EditUserModal from "../EditUserModal/EditUserModal";
import DeleteUserModal from "../DeleteUserModal/DeleteUserModal";
import { capitalize } from '../../helpers/funcs';
import "./filterbar.scss";
import "./datatable.scss";

const DataTable = (props) => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [careUnits, setCareUnits] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCareUnit, setSelectedCareUnit] = useState(0);
  const [careUnitName, setcareUnitName] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedResult, setSelectedResult] = useState([]);
  const [copyOfSelectedResult, setCopyOfSelectedResult] = useState([]);
  const [enhetWiseAssignments, setEnhetAssignments] = useState();
  const [enhetWiseLoaded, setEnhetWiseLoaded] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [onChange, setOnChange] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [totalRows, setTotalRows] = useState(0)
  const [ids, setIds] = useState([]);
  const userObj = JSON.parse(localStorage.getItem("tokens"));
  const currentUser = (userObj && userObj.data && typeof(userObj['data']) !== 'undefined' && (typeof(userObj['data'] != null))) ? userObj["data"]:[];

  let currentUserId = JSON.parse(localStorage.getItem('user_type'));
  
  let allCheckBoxes = document.querySelectorAll('input[type="checkbox"]');
  const [totalCheckBoxes, setTotalCheckBoxes] = useState(allCheckBoxes.length);

  const filterData = userArr => (
    userArr.filter(
      (user) =>
        user.first_name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        user.last_name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        user.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        user.adress.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
    ));

  useEffect(() => {
    
    if(users.length > 0){
    let tot = [...users];

    switch(currentUserId){
      case 1: setTotalCheckBoxes(tot.length);
              break;
      case 2: setTotalCheckBoxes(tot.filter(user => user.user_type_id !== "1").length);
              break;
      case 3: setTotalCheckBoxes(tot.filter(user => user.user_type_id === "4").length);
              break;
      default: setTotalCheckBoxes(0);
              break;
      }
    }

  }, [users]);


useEffect(() => {

  let usertype = localStorage.getItem('user_type');
  let token = localStorage.getItem('token');

      if (usertype !== null && token !== null) {
    axios
    .get(
      `${API_URL}checkLogin?/token=${token}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === true) {
              localStorage.setItem("loginStatus", "logged_in");
            } 
          }
         
        }).catch( (error) => {
          if(error.response.data.status === false && error.response.data.message === "Parallel login"){
            localStorage.clear();
            localStorage.setItem('parallel_login', true);
            document.location.href="/";
          }
      })
    }

    }, [])

  useEffect(() => {
    // setSelectedCareUnit(0);
    setLoading(true);
    axios
      .get(`${API_URL}getUsers`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const allUsers = response.data.data;
          setUsers(allUsers);
          setSearchResults(allUsers);
          setLoading(false);
          setTotalRows(response.data.total);
        }
      })
      .catch((error) => {
        console.log("Error", error.response);
        if(error.response.data.status === false && error.response.data.message === "Parallel login"){
          localStorage.clear();
          localStorage.setItem('parallel_login', true);
          document.location.href="/";
        }
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${API_URL}getEnhets`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {          
          setCareUnits(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error", error);
        if(error.response.data.status === false && error.response.data.message === "Parallel login"){
          localStorage.clear();
          localStorage.setItem('parallel_login', true);
          document.location.href="/";
        }
      });
  }, []);

  useEffect(() => {

    let filteredUsers = filterData(users);
    let filteredSelectedUsers = filterData(selectedResult);

    setSearchResults(filteredUsers);
    setCopyOfSelectedResult(filteredSelectedUsers);
  }, [searchTerm, users]);

  const handleSelectedCareUnit = (e) => {
    
    let idSelected  = Number(e.target.value)
    if(idSelected>0){
      axios.post(`${API_URL}usersByEnhet`, {
        enhet_id: idSelected
      },{
      headers:{
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('token')
      }    
    }).then(response => {
        if (response.status === 200) {

          let sorted = response.data.rows.sort((a, b) => a.count < b.count ? 1 : -1);
          setSelectedResult(sorted);
          setCopyOfSelectedResult(sorted);
          setEnhetAssignments(response.data.data)
          setIds(response.data.ids)
          setEnhetWiseLoaded(1)
        }
      })
      .catch((error) => {
        console.log("Error", error);
        if(error.response.data.status === false && error.response.data.message === "Parallel login"){
          localStorage.clear();
          localStorage.setItem('parallel_login', true);
          document.location.href="/";
        }
      }); 
      }
   
    let selectedUnitName = ""
      careUnits.forEach(careUnit => {
        let idToCompare = (typeof careUnit.id === "number" )? careUnit.id: Number(careUnit.id);
        if(idToCompare=== idSelected){
          selectedUnitName = careUnit.enhet_name
        }
      });
      setcareUnitName(selectedUnitName)
      setSelectedCareUnit(idSelected);
    
  };
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const onCheckboxChange = (e) => {
     if(e.target.name === "checkAll"){
      let ifChecked  = allChecked
      let totalrows = totalRows;
      let userIds = [];
      if(!ifChecked){
        //let chkds = document.querySelectorAll('input[name');
        for(let i=0; i<totalrows; i++){
          let chkds = document.getElementsByName(i)[0];
          if(chkds !== undefined){
          let userId = chkds.id;          
          userIds.push(Number(userId));
        }
        }
      }
      setAllChecked(!allChecked)
      setSelectedUsers([...userIds])
     }else{
      let selectedId = Number(e.target.name);
      let usersSelected = selectedUsers;
      if(usersSelected.includes(Number(selectedId))){
        const index = usersSelected.indexOf(Number(selectedId));
        if (index > -1) {
          usersSelected.splice(index, 1);
        }
      }else{
        usersSelected.push(Number(e.target.name))
      }
      setSelectedUsers([...usersSelected])
     }

  }
  
  let RenderUsersList=(props)=>{
    let selectedCareUnit = props.selectedCareUnit
    if(selectedCareUnit === 0){
      return (
        <table className="data-table" id="data_table">
            
            <thead>
              <tr>
              <th className="table-action-cell">
                <input type="checkbox" name="checkAll" onChange={(e)=>onCheckboxChange(e)} checked={allChecked?"checked":""}/>
              </th>
              <th className={currentUserId !== 3 ? 'table-action-cell' : 'd-none'}></th>
              <th className='table-action-cell'></th>
              <th>Namn</th>
              <th>Yrke</th>
              <th className="center">
              Totalt antal pass
              </th>
              <th>Kön</th>
              <th>Född</th>
              <th>Telefon</th>
              <th>E-post</th>
              <th>Kommentar</th>
              <th>Kommun</th>
              </tr>
            </thead>
              
              <tbody>{
                searchResults.length > 0 ?
                  searchResults.map((user, index) => {
                    let count = (user.count>0) ? user.count:"";
                    if(currentUserId === 3 && (user.user_type_id == "2" || user.user_type_id == "3")){
                      return null;
                    }else{
                    return (
                      <tr className="row" key={user.user_id} name={index} id={user.user_id}>
                        <td>
                        {user.user_type_id !== 1
                        ? <input type="checkbox" id={"chk_"+user.user_id} name={user.user_id} onChange={(e)=>onCheckboxChange(e)} checked={selectedUsers.includes(Number(user.user_id))?"checked":""}/>
                        : null }
                        </td>
                        <td>
                          {user.user_type_id !== 1 && currentUserId !== "3" ? (
                            <EditUserModal rowData={user} />
                          ) : null}
                        </td>
                        <td className={currentUserId !== 3 ? '' : 'd-none'}>
                          {user.user_type_id !== 1 && currentUserId !== "3" ? 
                            userObj['data']['id'] !== user.user_id ?
                            <DeleteUserModal rowData={user} /> : null
                           : null}
                        </td>
                        <td>
                          {capitalize(user.first_name)} {capitalize(user.last_name)}
                        </td>
                        <td>{capitalize(user.title)}</td>
                        <td style={{textAlign:"center"}}> {count}</td>
                        <td>
                          {user.gender === "1" ? "Man" : (user.gender === "0"? "Kvinna":"")}</td>
                        <td>{user.birth_date === "0000-00-00 00:00:00" ? '' : user.birth_date.slice(0, 10)}</td>
                        <td>{user.telefonnummer}</td>
                        <td>{user.username}</td>
                        <td>{user.comments}</td>
                        <td>{capitalize(user.adress)}</td>
                      </tr>
                    );
                  }
                  })
                  : null
                }
                </tbody>
            </table>

      );
    }else{

      return (
        <table className="data-table" id="data_table">
            
            <thead>
              <tr>
              <th className="table-action-cell">
                <input type="checkbox" name="checkAll" onChange={(e)=>onCheckboxChange(e)} checked={allChecked?"checked":""}/>
              </th>
              <th className={currentUserId !== 3 ? 'table-action-cell' : 'd-none'}></th>
              <th className='table-action-cell'></th>
              <th>Namn</th>
              <th>Yrke</th>
              <th className="center">
              Totalt antal pass
              </th>
              <th>Kön</th>
              <th>Född</th>
              <th>Telefon</th>
              <th>E-post</th>
              <th>Kommentar</th>
              <th>Kommun</th>
              </tr>
            </thead>
              
              <tbody>{
                
                copyOfSelectedResult.length > 0 ?
                copyOfSelectedResult.map((user, index) => {
                  let count = (user.count>0) ? user.count:"";
                  if(currentUserId === 3 && (user.user_type_id == "2" || user.user_type_id == "3")){
                    return null;
                  }else{
                  return (
                    <tr className="row" key={user.user_id} name={index} id={user.user_id}>
                      <td>
                      {user.user_type_id !== 1
                      ? <input type="checkbox" id={"chk_"+user.user_id} name={user.user_id} onChange={(e)=>onCheckboxChange(e)} checked={selectedUsers.includes(Number(user.user_id))?"checked":""}/>
                      : null }
                      </td>
                      <td>
                        {user.user_type_id !== 1 && currentUserId !== "3" ? (
                          <EditUserModal rowData={user} />
                        ) : null}
                      </td>
                      <td className={currentUserId !== 3 ? '' : 'd-none'}>
                        {user.user_type_id !== 1 && currentUserId !== 3 ? (
                          <DeleteUserModal rowData={user} />
                        ) : null}
                      </td>
                      <td>
                        {capitalize(user.first_name)} {capitalize(user.last_name)}
                      </td>
                      <td>{capitalize(user.title)}</td>
                      <td style={{textAlign:"center"}}> {count}</td>
                      <td>
                        {user.gender === "1" ? "Man" : (user.gender === "0"? "Kvinna":"")}</td>
                      <td>{user.birth_date === "0000-00-00 00:00:00" ? '' : user.birth_date.slice(0, 10)}</td>
                      <td>{user.telefonnummer}</td>
                      <td>{user.username}</td>
                      <td>{user.comments}</td>
                      <td>{capitalize(user.adress)}</td>
                    </tr>
                  );
                }
                })
                  : null
                }


                {
                
                searchResults.length > 0 ?
                  searchResults.map((user, index) => {
                    if(!ids.includes(user.user_id)){
                      let count = (user.count>0) ? user.count:"";
                      if(currentUserId === 3 && (user.user_type_id == "2" || user.user_type_id == "3")){
                        return null;
                      }else{
                      return (
                        <tr className="row" key={user.user_id} name={index} id={user.user_id}>
                          <td>
                          {user.user_type_id !== 1
                          ? <input type="checkbox" id={"chk_"+user.user_id} name={user.user_id} onChange={(e)=>onCheckboxChange(e)} checked={selectedUsers.includes(Number(user.user_id))?"checked":""}/>
                          : null }
                          </td>
                          <td>
                            {user.user_type_id !== 1 && currentUserId !== "3" ? (
                              <EditUserModal rowData={user} />
                            ) : null}
                          </td>
                          <td className={currentUserId !== 3 ? '' : 'd-none'}>
                            {(user.user_type_id !== 1 && currentUserId !== "3") ? 
                             userObj['data']['id'] !== user.user_id ?
                            <DeleteUserModal rowData={user} /> : null
                           : null}
                          </td>
                          <td>
                            {capitalize(user.first_name)} {capitalize(user.last_name)}
                          </td>
                          <td>{capitalize(user.title)}</td>
                          <td style={{textAlign:"center"}}> {}</td>
                          <td>
                            {user.gender === "1" ? "Man" : (user.gender === "0"? "Kvinna":"")}</td>
                          <td>{user.birth_date === "0000-00-00 00:00:00" ? '' : user.birth_date.slice(0, 10)}</td>
                          <td>{user.telefonnummer}</td>
                          <td>{user.username}</td>
                          <td>{user.comments}</td>
                          <td>{capitalize(user.adress)}</td>
                        </tr>
                      );
                    }
                  
                    }
                    
                  })
                  : null
                }
                </tbody>
            </table>
      
        );
    }
  
  }
  
  return (
    
    <>
      <NavBar title="TimTravel" />
      <div className="full-width-container"></div>
      <div className="filterbar-content">
        <div className="filterbar">
          <div className="form-element">
            <CssTextField
              id="query"
              name="query"
              label="Sök"
              type="text"
              variant="filled"
              value={searchTerm}
              onChange={handleChange}
              InputProps={{ disableUnderline: true }}
              fullWidth
            />
          </div>
          <div className="form-element">
            <FormControl
              variant="filled"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel htmlFor="outlined-age-native-simple">
                Vårdenhet
              </InputLabel>
              <CSSSelect
                native
                onChange={handleSelectedCareUnit}
                label="Vårdenhet"
                variant="filled"
                value={selectedCareUnit}
                disableUnderline={true}
              >
                <option></option>
                {careUnits.length > 0 ?
                careUnits.map((careUnit) => {
                  return (
                    <option key={careUnit.id} value={careUnit.id}>
                      {careUnit.enhet_name}
                    </option>
                  );
                }): null
              }
              </CSSSelect>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="search-toolbar">
        <span>
        {`${selectedUsers.length} av ${totalCheckBoxes} rader markerade`}
        </span>
        <div className="search-toolbar-actions">
          {selectedUsers.length === 0 ? (
            ""
          ) : (
            <OfferModal selectedUsers={selectedUsers} />
          )}
        </div>
      </div>
      {!loading ? 
        <RenderUsersList selectedCareUnit={selectedCareUnit}/>
      : (
        <Spinner />
      )}
    </>
  );
};
export default DataTable;