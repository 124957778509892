import React, { useState } from "react";
import { useAuth } from '../../context/auth';
import "./navbar.scss";
import { Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import {
  ExitToApp,
  PersonAdd,
  Menu,
  Search,
} from "@material-ui/icons";

const CompanyAdminNav = props => {

  const { setAuthTokens } = useAuth();

  const logOut = () => {
    setAuthTokens();
    localStorage.clear();
  };

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
    className="mobile-menu top"
    role="presentation"
      onClick={toggleDrawer("left", false)}
      onKeyDown={toggleDrawer("left", false)}
    >
      <List>
        <Link to="/" className="mob-menu-title-container" onClick={logOut}>
          <ListItem button className="mob-menu-title-container">
            <Typography
              variant="h6"
              className="tt-mobile-logo"
            >
              {props.title}
            </Typography>
          </ListItem>
        </Link>

        <Divider />

        <Link to="/search">
          <ListItem button>
            <ListItemIcon>
              <Search />
            </ListItemIcon>
            Sök
          </ListItem>
        </Link>

        <Link to="/shifts">
          <ListItem button>
            <ListItemIcon>
              <ListAltOutlinedIcon />
            </ListItemIcon>
            Pass
          </ListItem>
        </Link>

        <Link to="/add-careunit">
          <ListItem button>
            <ListItemIcon>
              <PersonAdd />
            </ListItemIcon>
            Lägg till vårdenhet
          </ListItem>
        </Link>

        <Link to="/add-booker">
          <ListItem button>
            <ListItemIcon>
              <PersonAdd />
            </ListItemIcon>
            Lägg till bokare
          </ListItem>
        </Link>

        <Link to="/add-employee">
          <ListItem button>
            <ListItemIcon>
              <PersonAdd />
            </ListItemIcon>
            Lägg till anställd
          </ListItem>
        </Link>

      </List>

      <Divider />

      <List>
        <Link to="/" onClick={logOut}>
          <ListItem button>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            Logga ut
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <div className="header-content">
      <header className="company-admin">
        <Link to="/" className="title-link">
        <Typography
          variant="h6"
          id="logo"
        >
          {props.title}
        </Typography>
        </Link>
        <nav>
          <Link to="/search">
            <Search />
            Sök
          </Link>
          <Link to="/shifts">
          <ListAltOutlinedIcon />
            Pass
          </Link>
          <Link to="/add-careunit">
            <PersonAdd />
            Lägg till vårdenhet
          </Link>
          <Link to="/add-booker">
            <PersonAdd />
            Lägg till bokare
          </Link>
          <Link to="/add-employee">
            <PersonAdd />
            Lägg till anställd
          </Link>
          {/* <Link to="/create-offer">
            <LocalOfferOutlined />
            Skapa erbjudande
          </Link> */}
        </nav>
        <div className="right-nav">
          <Link to="/" onClick={logOut}>
            <ExitToApp />
            Logga ut
          </Link>
          <div key={"left"}>
            <Button className="menu-icon" onClick={toggleDrawer("left", true)}>
              <span>Meny</span>
              <Menu />
            </Button>
            <SwipeableDrawer
              anchor={"left"}
              open={state["left"]}
              onClose={toggleDrawer("left", false)}
              onOpen={toggleDrawer("left", true)}
            >
              {list("left")}
            </SwipeableDrawer>
          </div>
        </div>
      </header>
      {/* <div className="account-type">
      <span>{props.account}</span>
    </div> */}
    </div>
  );
};

export default CompanyAdminNav;
