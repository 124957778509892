import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../constants/ApiLink';
import { Button } from "@material-ui/core";
import AlertMessage from '../AlertMessage/AlertMessage';
import {
  CssTextField,
  useStyles,
} from "../../components/FormTheme/FormTheme";

const BookerEditUser = props => {
  
  const [first_name, setFirstName] = useState(props.rowData.first_name);
  const [last_name, setLastName] = useState(props.rowData.last_name);
  const [userId, setUserId] = useState(props.rowData.user_id);
  const [username, setUserName] = useState(props.rowData.username);
  const [city, setCity] = useState(props.rowData.adress);
  const [telefonnummer, setPhoneNumber] = useState(props.rowData.telefonnummer);
  const [status, setStatusBase] = useState("");
    
  const updateUser = e => {
    
    e.preventDefault();

    // IF SUPERUSER SELECT WHICH COMPANY
    // IF COMPANY_ADMIN, GET COMPANY ...

    let editedUserObject = {
      userId: userId,
      active: 1,
      username: username,
      telefon: telefonnummer,
      adress: city,
      firstName: props.rowData.first_name,
      lastName: props.rowData.last_name,
      birthDate: props.rowData.birth_date,
      title: props.rowData.title,
      anstalning: props.rowData.anställningsnummer,
      keywords: props.rowData.keywords,
      comments: props.rowData.comments,
      userType: props.rowData.user_type_id,
      compnay: props.rowData.company,
      gender: props.rowData.gender,
      zip: props.rowData.zip_code,
      previousPosition: props.rowData.previousPosition,
      cvdoc: props.rowData.cv_doc_name,
    };

    let stringifiedEditedUserObject = JSON.stringify(editedUserObject);

    console.log(stringifiedEditedUserObject);
  
    axios
      .post(
        `${API_URL}updateUser`,
        {
          userObject: stringifiedEditedUserObject,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setStatusBase({ msg: `${first_name} ${last_name} uppdaterad`, key: Math.random() });
        }
      })
      .catch((error) => {
        console.log("Error", error);
        if(error.response.data.status === false && error.response.data.message === "Parallel login"){
          localStorage.clear();
          localStorage.setItem('parallel_login', true);
          document.location.href="/";
        }
      });
  }

  return (
    <>
    <form onSubmit={updateUser}>
      <div className="full-section">
        <div className="form-element">
          <CssTextField
            id="username"
            name="username"
            label="E-postadress"
            type="email"
            variant="outlined"
            value={username}
            onChange={(e) => setUserName(e.target.value)}
            fullWidth
          />
        </div>
      </div>
      <div className="third-section">
        <div className="form-element">
          <CssTextField
            id="telefonnummer"
            name="telefonnummer"
            label="Telefonnummer"
            type="phone"
            variant="outlined"
            value={telefonnummer}
            onChange={(e) => setPhoneNumber(e.target.value)}
            fullWidth
          />
        </div>
        <div className="form-element">
          <CssTextField
            id="city"
            name="city"
            label="Kommun"
            type="text"
            variant="outlined"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            fullWidth
          />
        </div>
      </div>
      <div className="form-actions-right-modal">
          <Button type="submit" size="large" className="primary-btn">
            Spara
          </Button>
        </div>
    </form>
    {status ? <AlertMessage key={status.key} message={status.msg} /> : null}
    </>
  );
};

export default BookerEditUser;
