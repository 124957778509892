import React, { useEffect, useState, useMemo } from "react";
import { Container, Paper } from "@material-ui/core";
import axios from "axios";
import { API_URL } from "../constants/ApiLink";
import NavBar from "../components/NavBar/NavBar";
import Spinner from "../components/Spinner/Spinner";
import { capitalize } from '../helpers/funcs';
import Dialog from "@material-ui/core/Dialog";
import { IconButton } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";

const HomePage = props => {

  const userObj = JSON.parse(localStorage.getItem('tokens'));
  //const userData = userObj['data'];
  const userData = (userObj && userObj.data && typeof(userObj['data']) !== 'undefined' && (typeof(userObj['data'] != null))) ? userObj["data"]:[];

  const {
    // id,
    // api_token,
    first_name,
    // last_name,
    user_type,
    // username
  } = userData;

  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userInModal, setUserInModal] = useState({});
  const [sortColumn, setSortColumn] = useState({
    path: "title", order: "asc"
  });

  const [open, setOpen] = useState(false);

  const onUserClick = id => {

    axios.post(`${API_URL}getUserById`, { 
      userId: id
    },{
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setUserInModal(response.data.data[0]);
          handleClickOpen();
        }
      })
      .catch((error) => {
        console.log("Error", error);
        if(error.response.data.status === false && error.response.data.message === "Parallel login"){
          localStorage.clear();
          localStorage.setItem('parallel_login', true);
          document.location.href="/";
        }
      });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setUserInModal({});
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}getOffers`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {

          let resp = response.data.data;
          resp.sort((a, b) => {
            let c = new Date(a.created_at);
            let d = new Date(b.created_at);
            return d-c;
          })
          
          setOffers(resp);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("Error", error);
        if(error.response.data.status === false && error.response.data.message === "Parallel login"){
          localStorage.clear();
          localStorage.setItem('parallel_login', true);
          document.location.href="/";
        }
      });

  }, []);

  return (
    <>
      <NavBar title="TimTravel" />
      <div className="full-width-container offer-table-container">
            <div className="content-desc-container">
            <h2>Pass</h2>
            </div>

            {loading ? (
                <Spinner />
              ) : (
            <table className="offer-table">
              <thead>
                <tr>
              {user_type === "1" ?
                (<th>Företag</th>) : null }
                <th>Typ</th>
                <th>Vårdenhet</th>
                <th>Pass startar</th>
                <th>Pass slutar</th>
                <th>Pass accepterat av</th>
                <th>Meddelande</th>
                <th>Skapades</th>
                <th>Skapades av</th>
              </tr>
                </thead>
                <tbody>{
                  offers.length > 0 ?
                  offers.map((offer, index) => {
                  const {bonus,
                         bonus_type,
                         company_id,
                         created_at,
                         end_time,
                         express,
                         id,
                         message,
                         shift_end,
                         shift_start,
                         start_time,
                         updated_at,
                         vard_enhet,
                         enhet_name,
                         company_name,
                         responder_id,
                         first_name,
                         last_name,
                         offer_creator,
                         offerer_name
                        } = offer;
                        
                return(
                  <tr className="row" key={index}>
                    {user_type === "1" 
                    ? (<><td className="first">{company_name}</td>
                    <td>
                    {express === "1"
                  ? <span className="express-pill">Express</span>
                  : <span className="standard-pill">Standard</span>}
                  </td>
                    </>)
                    : (<><td className="first">
                    {express === "1"
                  ? <span className="express-pill">Express</span>
                  : <span className="standard-pill">Standard</span>}
                  </td></>)}
              <td className="capitalize">{enhet_name}</td>
              <td>{shift_start.slice(0, 16)}</td>
              <td>{shift_end.slice(0, 16)}</td>
              <td className={first_name !== null && last_name !== null ? 'user-info-name' : ''} onClick={first_name !== null && last_name !== null ? e => onUserClick(responder_id) : null}>{first_name !== null && last_name !== null ? `${capitalize(first_name)} ${capitalize(last_name)}` : ''}</td>
              <td className="offer-table-msg mw-300" data-msg={message}>{message}</td>
              <td>{created_at.slice(0, 16)}</td>
              <td className={offerer_name !== null ? 'user-info-name last' : 'last'} onClick={offerer_name !== null ? e => onUserClick(offer_creator) : null}>{capitalize(offerer_name)}</td>
                </tr>
                )
              })
              : null
              }
              </tbody>
            </table>)}
            </div>
            <Dialog
              fullWidth={false}
              open={open}
              onClose={handleClose}
              aria-labelledby="max-width-dialog-title"
              className="user-info-modal"
            >
            <MuiDialogTitle disableTypography>
              <div className="user-info-modal-header">
                {userInModal.first_name && userInModal.last_name
                  ? <h2>{capitalize(userInModal.first_name)} {capitalize(userInModal.last_name)}</h2>
                  : null }
                {handleClose ? (
                  <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                ) : null}
              </div>
            </MuiDialogTitle>
            <DialogContent>
              <ul className="user-info-modal-list">
                {userInModal.telefonnummer 
                  ? <li><span className="list-title">Tel:</span><span className="list-item">{userInModal.telefonnummer}</span></li> 
                  : '' }
                {userInModal.username 
                  ? <li><span className="list-title">E-post:</span><span className="list-item"><a className="grey-link" href={'mailto:'+userInModal.username}>{userInModal.username}</a></span></li> 
                  : '' }
                {userInModal.birth_date && userInModal.birth_date !== "0000-00-00 00:00:00"
                  ? <li><span className="list-title">Född:</span><span className="list-item">{userInModal.birth_date.slice(0, 10)}</span></li>
                  : '' }
                {userInModal.adress 
                  ? <li><span className="list-title">Bor:</span><span className="list-item">{capitalize(userInModal.adress)}</span></li>
                  : '' }
                {userInModal.title
                  ? <li><span className="list-title">Yrke:</span><span className="list-item">{capitalize(userInModal.title)}</span></li>
                  : '' }
                {userInModal.keywords
                  ? <li><span className="list-title">Nyckelord:</span><span className="list-item">{userInModal.keywords}</span></li> : '' }
                {userInModal.comments
                  ? <li><span className="list-title">Kommentarer:</span><span className="list-item">{userInModal.comments}</span></li>
                  : '' }
              </ul>
            </DialogContent>
          </Dialog>
           </>
  );
};

export default HomePage;
