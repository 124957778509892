import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import NavBar from "../components/NavBar/NavBar";
import { Container, Paper, Button } from "@material-ui/core";
import AlertMessage from "../components/AlertMessage/AlertMessage";
import { API_URL } from "../constants/ApiLink";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/sv";
import TextField from '@material-ui/core/TextField';
import {
  CssTextField,
  CSSSelect,
  useStyles,
} from "../components/FormTheme/FormTheme";
import { userRole } from "../helpers/userdetails";

const AddEmployeePage = (props) => {

  let now = moment();
  let dateNow = now.format("YYYY-MM-DD");

  const [locale, setLocale] = useState("sv");

  const handleBirthDate = (date) => {
    const birth = moment(date).isValid()
      ? moment(date).format('YYYY-MM-DD')
      : ''
    setBirthDate(birth);
  };

  const userObj = JSON.parse(localStorage.getItem("tokens"));
  const userObjInfo = userObj["data"];
  const { company_id, user_type } = userObjInfo;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [birthDate, setBirthDate] = useState(new Date());
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [workTitle, setWorkTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");
  const [keyWords, setKeyWords] = useState("");
  const [company, setCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const [prevPosition, setPrevPosition] = useState("");
  const [status, setStatusBase] = useState("");
  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("user_type")
  );

  const classes = useStyles();

  useEffect(() => {
    axios
      .get(`${API_URL}getCompanies`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const allCompanies = response.data.data;
          allCompanies.unshift({});
          setCompanies(allCompanies);
        }
      })
      .catch((error) => {
        console.log("Error", error);
        if(error.response.data.status === false && error.response.data.message === "Parallel login"){
          localStorage.clear();
          localStorage.setItem('parallel_login', true);
          document.location.href="/";
        }
      });
  }, []);

  const addEmployee = (e) => {
    e.preventDefault();

    let employeeObject = {
      username: email,
      password: "",
      firstName: firstName,
      lastName: lastName,
      active: 1,
      compnay: user_type !== "1" ? company_id : company,
      userType: 4,
      birthDate: birthDate,
      gender: gender,
      title: workTitle,
      anstalning: employeeNumber,
      telefon: phone,
      adress: city,
      zip: "",
      keywords: keyWords,
      comments: comments,
      // previousPosition: prevPosition,
      // cvdoc: '',
    };

    let stringifiedEmployeeObject = JSON.stringify(employeeObject);

    axios
      .post(
        `${API_URL}addUser`,
        {
          userObject: stringifiedEmployeeObject,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setStatusBase({
            sev: "success",
            msg: `${firstName} ${lastName} tillagd`,
            key: Math.random(),
          });
          setFirstName("");
          setLastName("");
          setGender("");
          setBirthDate(new Date());
          setEmployeeNumber("");
          setWorkTitle("");
          setPhone("");
          setStreet("");
          setZipCode("");
          setCity("");
          setEmail("");
          setComments("");
          setKeyWords("");
          setPrevPosition("");
        }
      })
      .catch((error) => {
        console.log("Error", error);
        setStatusBase({
          sev: "error",
          msg: `${firstName} ${lastName} finns redan i databasen`,
          key: Math.random(),
        });
        if(error.response.data.status === false && error.response.data.message === "Parallel login"){
          localStorage.clear();
          localStorage.setItem('parallel_login', true);
          document.location.href="/";
        }
      });
  };

  if (currentUser !== "1" && currentUser !== "2") {
    return <Redirect to={{ pathname: "/404" }} />;
  }

  return (
    <Container>
      <NavBar title="TimTravel" />
      <main className="menu-margin">
        <form onSubmit={addEmployee}>
          <div className="content">
            <Paper elevation={2} className="content-card">
              <h2>Lägg till anställd</h2>
              {userRole() === "SUPER_ADMIN" ? (
                <div className="full-section">
                  <div className="form-element">
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="outlined-age-native-simple">
                        Företag *
                      </InputLabel>
                      <CSSSelect
                        native
                        label="Företag *"
                        required
                        variant="outlined"
                        onChange={(e) => setCompany(e.target.value)}
                        inputProps={{
                          name: "company",
                          id: "company",
                        }}
                      >
                        {companies.map((company, index) => {
                          return (
                            <option key={index} value={company.id}>
                              {company.company_name}
                            </option>
                          );
                        })}
                      </CSSSelect>
                    </FormControl>
                  </div>
                </div>
              ) : null}
              <div className="full-section">
                <div className="form-element">
                  <CssTextField
                    id="keyWords"
                    name="keyWords"
                    label="Nyckelord"
                    type="text"
                    variant="outlined"
                    value={keyWords}
                    onChange={(e) => setKeyWords(e.target.value)}
                    fullWidth
                  />
                </div>
              </div>
              <div className="third-section">
                <div className="form-element">
                  <CssTextField
                    id="firstName"
                    name="firstName"
                    label="Förnamn"
                    type="text"
                    variant="outlined"
                    value={firstName}
                    required
                    onChange={(e) => setFirstName(e.target.value)}
                    fullWidth
                  />
                </div>
                <div className="form-element">
                  <CssTextField
                    id="lastName"
                    name="lastName"
                    label="Efternamn"
                    type="text"
                    variant="outlined"
                    value={lastName}
                    required
                    onChange={(e) => setLastName(e.target.value)}
                    fullWidth
                  />
                </div>
                <div className="form-element">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Kön *
                    </InputLabel>
                    <CSSSelect
                      native
                      onChange={(e) => setGender(e.target.value)}
                      label="Kön *"
                      variant="outlined"
                      required
                      value={gender}
                      inputProps={{
                        name: "gender",
                        id: "gender",
                      }}
                    >
                      <option value={""}></option>
                      <option value={1}>Man</option>
                      <option value={0}>Kvinna</option>
                    </CSSSelect>
                  </FormControl>
                </div>
              </div>
              <div className="fifty-section">
                <div className="form-element">
                  <CssTextField
                    id="email"
                    name="email"
                    label="E-postadress"
                    type="email"
                    variant="outlined"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                  />
                </div>
                <div className="form-element datetime-element greyed">
                   <TextField
                    id="date"
                    label="Födelsedatum"
                    type="date"
                    fullWidth
                    onChange={e => handleBirthDate(e.target.value)}
                    value={birthDate}
                    className={classes.textField}
                    inputProps={{
                      min: '1920-01-01',
                      max: '9999-12-12'
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
              <div className="fifty-section">
                <div className="form-element">
                  <CssTextField
                    id="employeeNumber"
                    name="employeeNumber"
                    label="Anställningsnummer"
                    type="number"
                    variant="outlined"
                    value={employeeNumber}
                    required
                    onChange={(e) => setEmployeeNumber(e.target.value)}
                    fullWidth
                  />
                </div>
                <div className="form-element">
                  <CssTextField
                    id="workTitle"
                    name="workTitle"
                    label="Yrke"
                    type="text"
                    variant="outlined"
                    value={workTitle}
                    required
                    onChange={(e) => setWorkTitle(e.target.value)}
                    fullWidth
                  />
                </div>
              </div>
              <div className="fifty-section">
                <div className="form-element">
                  <CssTextField
                    id="phone"
                    name="phone"
                    label="Telefonnummer"
                    type="phone"
                    variant="outlined"
                    value={phone}
                    required
                    onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); }}}
                    onChange={(e) => setPhone(e.target.value)}
                    fullWidth
                  />
                </div>
                <div className="form-element">
                  <CssTextField
                    id="city"
                    name="city"
                    label="Kommun"
                    type="text"
                    variant="outlined"
                    value={city}
                    required
                    onChange={(e) => setCity(e.target.value)}
                    fullWidth
                  />
                </div>
              </div>
              <div className="full-section">
                <div className="form-element">
                  <CssTextField
                    id="comments"
                    name="comments"
                    label="Kommentarer"
                    type="text"
                    variant="outlined"
                    value={comments}
                    multiline
                    rows={6}
                    onChange={(e) => setComments(e.target.value)}
                    fullWidth
                  />
                </div>
              </div>
              <div className="form-actions-right">
                <Button type="submit" size="large" className="primary-btn">
                  Lägg till anställd
                </Button>
              </div>
            </Paper>
          </div>
        </form>
      </main>
      {status ? (
        <AlertMessage key={status.key} message={status.msg} sev={status.sev} />
      ) : null}
    </Container>
  );
};
export default AddEmployeePage;
