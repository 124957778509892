import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import SuperAdminEditUser from './SuperAdminEditUser';
import BookerEditUser from './BookerEditUser';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import './editusermodal.scss';

export default function EditUserModal(props) {

  const [currentUser, setCurrentUser] = useState(localStorage.getItem('user_type'));
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  return (
    <>
      <span className="edit-user-pen" onClick={handleClickOpen}>
        <EditIcon />
      </span>
      
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className="create-offer-modal"
      >
        <MuiDialogTitle disableTypography>
          <div className="create-offer-dialog-title">
            <h2>Redigera användare</h2>
            {handleClose ? (
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </div>
        </MuiDialogTitle>
        <DialogContent>
          {currentUser === "1" || currentUser === "2" 
          ? <SuperAdminEditUser rowData={props.rowData} id={props.id} />
          : <BookerEditUser rowData={props.rowData} id={props.id} />
          }
        </DialogContent>
      </Dialog>
    </>
  );
}
