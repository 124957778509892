export function userRole() {
    const user_type = JSON.parse(localStorage.getItem('user_type'));
  let account_type = '';

  switch(user_type){
    case 1: account_type = 'SUPER_ADMIN';
            break;
    case 2: account_type = 'BOOKER_ADMIN';
            break;
    case 3: account_type = 'BOOKER';
            break;
    default: break;
  }

  return account_type;
}
        
