import { makeStyles, withStyles } from "@material-ui/core/styles";
import { TextField, Select, OutlinedInput } from "@material-ui/core";

export const CssTextField = withStyles({
  root: {
    fontFamily: "'Montserrat', sans-serif", 
    backgroundColor: 'white',
    borderRadius: '4px',
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4e4e4e",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4C4C4C",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4C4C4C"
    },
    "& .MuiOutlinedInput-input": {
      color: " #4e4e4e"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: " #4e4e4e"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: " #4e4e4e"
    },
    "& .MuiInputLabel-outlined": {
      color: "#4e4e4e",
      backgroundColor: 'white'
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#4e4e4e",
      borderRadius: '4px',
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#4e4e4e",
      borderRadius: '4px',
      margin: '0'
    },
    '& .MuiFilledInput-input': {
      backgroundColor: 'white',
      borderRadius: '4px',
      borderColor: 'white',
      border: 'none'
    },
    "& .MuiInputLabel-filled.Mui-focused": {
      color: "#4e4e4e",
      borderBottom: '0',
    },
   
  },
})(TextField);

export const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    backgroundColor: 'white',
    borderRadius: '4px',
    textColor: '#4e4e4e',
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4e4e4e",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4C4C4C",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4C4C4C"
    },
    "& .MuiOutlinedInput-input": {
      color: " #4e4e4e"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: " #4e4e4e"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: " #4e4e4e",
      backgroundColor: 'white',
    },
    "& .MuiInputLabel-outlined": {
      color: "#4e4e4e",
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#4e4e4e",
      borderRadius: '4px',
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#4C4C4C",
      borderRadius: '4px',
      margin: '0'
    },
    "& .MuiInputLabel-filled": {
      color: "#4e4e4e",
      backgroundColor: 'white',
    },
    "&:hover .MuiInputLabel-filled": {
      color: "#4e4e4e",
      borderRadius: '4px',
      backgroundColor: 'white',
    },
    "& .MuiInputLabel-filled.Mui-focused": {
      color: "#4C4C4C",
      backgroundColor: 'white',
      borderRadius: '4px',
      margin: '0'
    },
    "& .MuiSelect-filled": {
      backgroundColor: 'white',
      borderRadius: '4px'
    },
  },
}));

export const CSSSelect = withStyles({
  // ".Mui-focused": {
  //   backgroundColor: 'white'
  // }
})(Select);

export const CSSOutlinedInput = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#4e4e4e',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#4e4e4e',
      },
      '&:hover fieldset': {
        borderColor: '#4e4e4e',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4e4e4e',
      },
      "& .Mui-focused.MuiInputLabel-root": {
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      }
    },
  },
})(OutlinedInput);