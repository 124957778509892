import React from "react";
import { Checkbox, Paper, Button } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const StandardOffer = props => {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div className="offer-container">
      <Paper elevation={2} className="content-card offer-content">
        <h1>Välkommen till TimTravel!</h1>
        <h3>Nedan pass kan nu bokas, först till kvarn gäller!</h3>
        <ul>
          <li>typ: standard....</li>
          <li>Tid: 12.00-16:00</li>
          <li>Datum: 23/10</li>
        </ul>
        <div className="offer-actions">
          <FormControlLabel
            value="end"
            control={<Checkbox color="default" />}
            label="Ja, jag vill boka detta pass."
            labelPlacement="end"
            className="checkbox-label"
            onChange={handleChange}
            checked={checked}
          />
          <Button type="submit" size="large" className="primary-btn">
            Boka pass
          </Button>
        </div>
      </Paper>
    </div>
  );
};
export default StandardOffer;
