import React, { useEffect, useState } from "react";
import { IconButton, Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import CloseIcon from "@material-ui/icons/Close";
import CreateOffer from "../CreateOffer";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import axios from 'axios';
import { API_URL } from '../../../constants/ApiLink';

export default function OfferModal(props) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  

  // useEffect(() => {
  //   axios
  //     .get(`${API_URL}getEnhetsId`, {
  //       enhetId: 1
  //     },{
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //     })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         const allUsers = response.data.data;
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("Error", error);
  //     });
  // }, []);

// console.log('selectedUsers in offer-modal', props.selectedUsers);

  return (
    <>
      <Button className="create-offer-btn" onClick={handleClickOpen}>
        <LocalOfferOutlinedIcon className="add-offer-icon" />
        Skapa erbjudande
      </Button>

      {/* <Button
        variant="outlined"
        className="create-offer-btn"
        onClick={handleClickOpen}
      >
        <AddIcon className="add-offer-icon" />
        Skapa erbjudande
      </Button> */}

      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className="create-offer-modal"
      >
        <MuiDialogTitle disableTypography>
          <div className="create-offer-dialog-title">
            <h2>Skapa erbjudande</h2>
            {handleClose ? (
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </div>
        </MuiDialogTitle>
        <DialogContent>
          <CreateOffer selectedUsers={props.selectedUsers} setOpen={setOpen} />
        </DialogContent>
      </Dialog>
    </>
  );
}
