import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constants/ApiLink";
import { Button, Paper } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useAuth } from "../context/auth";
import {
  CssTextField,
  CSSOutlinedInput,
  useStyles,
} from "../components/FormTheme/FormTheme";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";
const md5 = require("md5");
const sha512 = require("sha512");

const LoginPage = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { setAuthTokens } = useAuth();

  const classes = useStyles();

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const referer = props.location.referer || '/';
  let history = useHistory();
  const handleSubmit = (e) => {
    e.preventDefault();
    let secretKey = "timtravel1.0";
    let hasher = sha512.hmac(secretKey);
    let passwordHash = hasher.finalize(password);
    let hashedPassword = md5(passwordHash.toString("hex"));
    let md5userName = md5(userName);
    const loginData = {
      username: md5userName,
      password: hashedPassword,
    };
    let loginObject = JSON.stringify(loginData);

    axios
      .post(`${API_URL}login`, {
        loginObject: loginObject,
      })
      .then((response) => {
        if (response.status === 200) {
          setAuthTokens(response.data);
          localStorage.setItem("loginStatus", "logged_in");
          localStorage.setItem("user_type", response.data.data.user_type);
          localStorage.setItem("token", response.data.apiToken);
          setIsLoggedIn(true);
          history.push('/search');
        } else {
          setIsError(true);
          localStorage.clear();
          console.log('Login failed', response);
        }
      })
      .catch((error) => {
        setIsError(true);
      });
  };

  if (localStorage.getItem("loginStatus") === "logged_in") {
    return <Redirect to="/search" />;
  }

  return (
    <div className="login-wrapper">
      <Paper elevation={2} className="content-card login-container">
        <h1 className="logo">TimTravel</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-element">
            <CssTextField
              id="username"
              name="username"
              label="E-postadress"
              type="text"
              variant="outlined"
              required
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              fullWidth
            />
          </div>
          <div className="form-element">
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Lösenord
              </InputLabel>
              <CSSOutlinedInput
                name="password"
                id="password"
                type={showPassword ? "text" : "password"}
                value={password}
                variant="outlined"
                required
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
          </div>
          <div className="form-element">
            {isError ? (
              <Alert severity="error">
                <AlertTitle>Fel användarnamn eller lösenord.</AlertTitle>
              </Alert>
            ) : (
              ""
            )}
          </div>
          <div className="login-actions">
            <Button type="submit" size="large" className="login-button">
              Logga in
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
};

export default LoginPage;
