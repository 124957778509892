import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Checkbox, Paper, Button } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import AlertMessage from "../../components/AlertMessage/AlertMessage";
import { API_URL } from "../../constants/ApiLink";
import * as moment from "moment";
import OfferExpiredPage from '../../views/OfferExpiredPage'
import { Alert, AlertTitle } from "@material-ui/lab";
import axios from "axios";
function InvalidOffer(props) {
  return (
    <div className="offer-container">
      <Paper elevation={2} className="content-card offer-content">
        <h2 style={{ textAlign: "center" }}>This offer is invalid!</h2>
      </Paper>
    </div>
  );
}
const ExpressOffer = (props) => {

  const [offerInfo, setOfferInfo] = useState([]);
  const [isError, setIsError] = useState(false);
  const [currCompany, setCurrCompany] = useState('');

  let offerEndTime = offerInfo.end_time || '';

  const calculateTimeLeft = () => {
    let year = new Date().getFullYear();
    const difference = +new Date(`${year}-${offerEndTime.slice(5, 10)}`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        dagar: Math.floor(difference / (1000 * 60 * 60 * 24)),
        timmar: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minuter: Math.floor((difference / 1000 / 60) % 60),
        sekunder: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [year] = useState(new Date().getFullYear());

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span className="offer-time-span">
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  const { offer } = props.match.params;
  let res = offer.split("offer!");
  let offerNuser = res[1].split("!");
  let offerId = offerNuser["0"];
  let userIdNkey = offerNuser["1"].split("~");
  let userId = userIdNkey[0];
  let compareKey = userIdNkey[1];
  let idInCompareKey = compareKey / 0.2 - offerId;
  let offerInCompareKey = compareKey / 0.2 - userId;
  let safeParams = true;

  const [checked, setChecked] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  if (
    userId + "" !== idInCompareKey + "" &&
    offerId + "" !== offerInCompareKey + ""
  ) {
    safeParams = false;
  }

  const [status, setStatusBase] = useState("");

  
  const [loadData, setLoadData] = useState(false);
  if (loadData === false) {

    axios
      .post(`${API_URL}openInfo`, {
        offerId: offerId,
      })
      .then((response) => { 
        if (response.status === 200 && response.statusText === "OK") {
          setOfferInfo(response.data.data[0]);
          setCurrCompany(response.data.offer_company);
          setLoadData(true);
        }
      }).catch((error) => {
        if(error.response.status === 401){
          setOfferInfo([]);
          setLoadData(true);
        }
      });
  }
    let expiredOffer = false;
    if(offerInfo.length === 0)  {
      expiredOffer = true;
    }else if(offerInfo.length> 0 && offerInfo.responder_id>0){
      expiredOffer = true;
    }else if(timerComponents.length === 1){
      expiredOffer = true;
    }

    if (expiredOffer) {      
      return <OfferExpiredPage />;
    }
   else {

    const {
      start_time,
      end_time,
      enhet_name,
      shift_start,
      shift_end,
      bonus,
      bonus_type,
      message,
      id,
      express,
      responder_id
    } = offerInfo;
      
    const acceptShift = () => {
      if (checked === true) {
        let offerObject = {
          offer: offerId,
          userId: userId,
        };

        let stringifiedOfferObject = JSON.stringify(offerObject);

        axios
          .post(`${API_URL}acceptOffer`, {
            offerObj: stringifiedOfferObject,
          })
          .then((response) => {
            if (response.status === 200 && response.statusText === "OK") {
              setStatusBase({ 
                sev: 'success',
                msg: `Passet accepterat`, 
                key: Math.random() });
            }
            setTimeout(() => props.history.push('/offer-success'), 5000);
          }).catch((error) => {
            if(error.response.status === 401){
              setOfferInfo([]);
              setLoadData(true);
            }
          })
          

      } else{
        setStatusBase({
          sev: 'error',
          msg: `Du behöver klicka i boxen för att tacka ja till passet.`,
          key: Math.random(),
        });
      }
    };
    
    return (
      
      <div className="offer-container">
        <Paper elevation={0} className="content-card offer-content">
          <h2>Välkommen till TimTravel!</h2>
          <p>Nedan pass kan nu bokas, först till kvarn gäller.</p>
          <div className="fifty">
            <div className="fifty-item">
              <ul className="offer-list">
              <li>
                  <span className="list-title">Företag:</span>
                  <span className="list-item">{currCompany}</span>
                </li>
                <li>
                  <span className="list-title">Vårdenhet:</span>
                  <span className="list-item">{enhet_name}</span>
                </li>
                <li>
                  <span className="list-title">Passet börjar:</span>
                  <span className="list-item">{shift_start}</span>
                </li>
                <li>
                  <span className="list-title">Passet slutar:</span>
                  <span className="list-item">{shift_end}</span>
                </li>
                {express === "1" ? (
                  <li>
                    <span className="list-title">Bonus:</span>
                    <span className="list-item">
                      {bonus} {bonus_type === "1" ? 'kr' : 'kr / timmen'}
                    </span>
                  </li>
                ) : null}
              </ul>
            </div>
            <div className="fifty-item"></div>
            {/* {express === 1 ? (
              <div className="fifty-item timer">
            {timerComponents.length 
            ? (
              <ul className="offer-list">
                <li className="offer-time-li">
              <span className="list-title">Återstående tid:</span>
              {timerComponents}
              </li>
              </ul>
              ) 
              : <span>Erbjudandet gäller inte längre.</span>}
              </div>
            ) : null} */}
          </div>
          {message !== " " ?
          (<div className="offer-message-container">
            <p>{message}</p>
          </div>)
          : null}
          <div className="offer-actions">
            <FormControlLabel
              value="end"
              control={<Checkbox color="default" />}
              label="Ja tack, jag tar gärna passet!"
              labelPlacement="end"
              className="checkbox-label"
              checked={checked}
              onChange={handleChange}
            />
            <Button
              onClick={acceptShift}
              type="submit"
              size="large"
              className="primary-btn"
            >
              Boka pass
            </Button>
          </div>
        </Paper>
        {status ? <AlertMessage key={status.key} message={status.msg} sev={status.sev} /> : null}
      </div>
    );
  }
};
export default ExpressOffer;

