import React, { useEffect, useState } from "react";
import { Switch, Route, HashRouter} from "react-router-dom";
import { API_URL } from "./constants/ApiLink";
import axios from "axios";
import HomePage from "./views/HomePage";
import LoginPage from "./views/LoginPage";
import AddCompanyPage from "./views/AddCompanyPage";
import AddCompanyAdmin from "./views/AddCompanyAdmin";
import AddCareUnitPage from "./views/AddCareUnitPage";
import AddBookerPage from "./views/AddBookerPage";
import AddEmployeePage from "./views/AddEmployeePage";
import OfferPage from "./views/OfferPage";
import OfferExpiredPage from "./views/OfferExpiredPage";
import OfferSuccessPage from "./views/OfferSuccessPage";
import NotFoundPage from "./views/NotFoundPage";
import PrivateRoute from "./PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import { AuthContext } from "./context/auth";
import DataTable from "./components/DataTable/DataTable";
import "./index.scss";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "@material-ui/core";

const App = () => {

  
  const md5 = require("md5");
  const sha512 = require("sha512");

  const existingTokens = JSON.parse(localStorage.getItem("tokens"));
  const [authTokens, setAuthTokens] = useState(existingTokens);

  const userObj = JSON.parse(localStorage.getItem("tokens"));
  const currentUser = (userObj && userObj.data && typeof(userObj['data']) !== 'undefined' && (typeof(userObj['data'] != null))) ? userObj["data"]:[];
    
  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("tokens"));
    if (loggedInUser) {
      const foundUser = loggedInUser;
      setAuthTokens(existingTokens);
    }
  }, []);

  const setTokens = (data) => {
    localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
  };


  const [modalHeader, setModalHeader] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modal, setModal] = useState(false);

  const handleModalClose=()=>{
    localStorage.clear();
    setModal(false);
  }

  useEffect(() => {
  
      let usertype = localStorage.getItem('user_type');
      let token = localStorage.getItem('token');

      if (usertype !== null && token !== null) {
        axios
        .get(
          `${API_URL}checkLogin?/token=${token}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then((response) => {
          //console.log('resp', response);
          if (response.status === 200) {
            if (response.data.status === true) {
              localStorage.setItem("loginStatus", "logged_in");   
              //console.log('inside', response);             
            }
          }
        }).catch( (error) => {
          //console.log(error.response);
          localStorage.clear();
          localStorage.setItem('parallel_login', true);
          document.location.href="/";
          if(error.response.data.status === false && error.response.data.message === "Parallel login"){
            localStorage.clear();
            localStorage.setItem('parallel_login', true);
            document.location.href="/";
          }
      })

      }else if(localStorage.getItem('parallel_login')){
        setModalHeader("Du har blivit utloggad!");
        setModalBody("Ditt konto används någon annanstans.");
        setModal(true);
      }
      
  }, []);

  const logOut = () => {
    setAuthTokens();
    localStorage.clear();
  };

  return (
    <>
    <div className="wrapper">
      <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
        <HashRouter basename="/">
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route path="/offer-expired" component={OfferExpiredPage} />
            <Route path="/offer-success" component={OfferSuccessPage} />
            <PrivateRoute path="/shifts" component={HomePage} />
            <PrivateRoute path="/search" component={DataTable} />
            <PrivateRoute path="/add-company" component={AddCompanyPage} />
            <PrivateRoute path="/add-companyadmin" component={AddCompanyAdmin} />
            <PrivateRoute path="/add-careunit" component={AddCareUnitPage} />
            <PrivateRoute path="/add-booker" component={AddBookerPage} />
            <PrivateRoute path="/add-employee" component={AddEmployeePage} />
            <Route path="/:offer" component={OfferPage} />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </HashRouter>
      </AuthContext.Provider>

      <Dialog fullWidth={false} open={modal}  aria-labelledby="max-width-dialog-title">
          <MuiDialogTitle disableTypography>
            <div>
              <h2 className="m-0">{modalHeader}</h2>
            </div>
          </MuiDialogTitle>
          <DialogContent>
            <p className="pb-12">{modalBody}</p>
            <div className="form-actions-right pb-16">
              <Button type="button" onClick={handleModalClose} size="large" className="primary-btn">
                Ok
              </Button>
            </div>
          </DialogContent>
        </Dialog>
    </div>
        
    </>
  );
};

export default App;
