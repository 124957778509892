import React, { useState } from 'react';
import { API_URL } from "../constants/ApiLink";
import axios from "axios";
import NavBar from '../components/NavBar/NavBar';
import AlertMessage from '../components/AlertMessage/AlertMessage';
import { Container, Paper, Button } from '@material-ui/core';
import { CssTextField } from '../components/FormTheme/FormTheme';
import { Redirect } from 'react-router-dom';

const AddCompanyPage = props => {

  const [companyName, setCompanyName] = useState('');
  const [currentUser, setCurrentUser] = useState(localStorage.getItem('user_type'));
  const [status, setStatusBase] = useState("");

  const handleSubmit = e => {
    e.preventDefault();
    
    let companyData = { companyName: companyName }
    let companyObject = JSON.stringify(companyData);

    axios.post(`${API_URL}addCompany`, {
        companyObj: companyObject 
      },{
      headers:{
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('token')
      }    
    }).then(response => {
      console.log('RES', response);
        if (response.status === 200) {
          setStatusBase({ msg: `${companyName} tillagd`, key: Math.random() });
          setCompanyName('');
          if(response.data.message === 'Company already added'){
            setStatusBase({ sev: 'error', msg: `${companyName} finns redan i databasen`, key: Math.random() });
          }
        }
      })
      .catch((error) => {
        console.log("Error", error);
        if(error.response.data.status === false && error.response.data.message === "Parallel login"){
          localStorage.clear();
          localStorage.setItem('parallel_login', true);
          document.location.href="/";
        }
      }); 
    }

    if(currentUser !== "1") { return <Redirect to={{ pathname: '/404'}} /> }
  
  return(
    <Container>
    <NavBar title="TimTravel"/>
    <main className="menu-margin">
      <form onSubmit={handleSubmit}>
      <div className="content">
    <Paper elevation={2} className="content-card">
    <h2>Lägg till företag</h2>
    <div className="full-section">
    <div className="form-element">
      <CssTextField 
        id='companyName' 
        name='companyName'
        label='Företagsnamn'
        type='text'
        variant="outlined" 
        required
        value={companyName}
        onChange={e => setCompanyName(e.target.value)}
        fullWidth />
        </div>
        </div>
        <div className="form-actions-right">
        <Button 
            type="submit" 
            size="large" 
            className="primary-btn"
            >Lägg till företag</Button>
        </div>
    </Paper>
      </div>
      </form>
    </main>
    {status ? <AlertMessage key={status.key} message={status.msg} sev={status.sev} /> : null}
    </Container>
)
}
export default AddCompanyPage;