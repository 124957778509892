import React, { useState } from "react";
import { useAuth } from "../../context/auth";
import "./navbar.scss";
import { Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import {
  Business,
  LocalHospital,
  ExitToApp,
  PersonAdd,
  Search,
} from "@material-ui/icons";

const SuperAdminNav = (props) => {
  const { setAuthTokens } = useAuth();

  const logOut = () => {
    setAuthTokens();
    localStorage.clear();
  };

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const list = (anchor) => (
    <div
      className="mobile-menu top"
      role="presentation"
      onClick={toggleDrawer("left", false)}
      onKeyDown={toggleDrawer("left", false)}
    >
      <List>
        <Link to="/" className="mob-menu-title-container" onClick={logOut}>
          <ListItem button className="mob-menu-title-container">
            <Typography variant="h6" className="tt-mobile-logo">
              {props.title}
            </Typography>
          </ListItem>
        </Link>

        <Divider />

        <Link to="/search">
          <ListItem button>
            <ListItemIcon>
              <Search />
            </ListItemIcon>
            Sök
          </ListItem>
        </Link>

        <Link to="/shifts">
          <ListItem button>
            <ListItemIcon>
              <ListAltOutlinedIcon />
            </ListItemIcon>
            Pass
          </ListItem>
        </Link>

        <Link to="/add-company">
          <ListItem button>
            <ListItemIcon>
              <Business />
            </ListItemIcon>
            Lägg till företag
          </ListItem>
        </Link>

        <Link to="/add-careunit">
          <ListItem button>
            <ListItemIcon>
              <LocalHospital />
            </ListItemIcon>
            Lägg till vårdenhet
          </ListItem>
        </Link>

        <Link to="/add-companyadmin">
          <ListItem button>
            <ListItemIcon>
              <PersonAdd />
            </ListItemIcon>
            Lägg till företagsadmin
          </ListItem>
        </Link>

        <Link to="/add-booker">
          <ListItem button>
            <ListItemIcon>
              <PersonAdd />
            </ListItemIcon>
            Lägg till bokare
          </ListItem>
        </Link>

        <Link to="/add-employee">
          <ListItem button>
            <ListItemIcon>
              <PersonAdd />
            </ListItemIcon>
            Lägg till anställd
          </ListItem>
        </Link>

        {/* <Link to="/create-offer">
          <ListItem button>
            <ListItemIcon>
              <LocalOfferOutlined />
            </ListItemIcon>
            Skapa erbjudande
          </ListItem>
        </Link> */}
      </List>

      <Divider />

      <List>
        <Link to="/" onClick={logOut}>
          <ListItem button>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            Logga ut
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <div className="header-content">
      <header className="super-admin">
        <Link to="/" className="title-link">
          <Typography variant="h6" id="logo">
            {props.title}
          </Typography>
        </Link>
        <nav>
          <Link to="/search">
            <Search />
            Sök
          </Link>

          <Link to="/shifts">
          <ListAltOutlinedIcon />
            Pass
          </Link>

          <Button
            ref={anchorRef}
            className="admin-btn-dropdown"
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <Business /> Företag <ArrowDropDownIcon />
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      onKeyDown={handleListKeyDown}
                      id="admin-dropdown"
                    >
                      <MenuItem onClick={handleClose}>
                        <Link to="/add-company">
                          <Business />
                          Lägg till företag
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <Link to="/add-careunit">
                          <LocalHospital />
                          Lägg till vårdenhet
                        </Link>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Link to="/add-companyadmin">
            <PersonAdd />
            Lägg till företagsadmin
          </Link>
          <Link to="/add-booker">
            <PersonAdd />
            Lägg till bokare
          </Link>
          <Link to="/add-employee">
            <PersonAdd />
            Lägg till anställd
          </Link>
          {/* <Link to="/create-offer">
            <LocalOfferOutlined />
            Skapa erbjudande
          </Link> */}

        </nav>
        <div className="right-nav">
          <Link to="/" onClick={logOut}>
            <ExitToApp />
            Logga ut
          </Link>
          <div key={"left"}>
            <Button className="menu-icon" onClick={toggleDrawer("left", true)}>
              <span>Meny</span>
              <MenuIcon />
            </Button>
            <SwipeableDrawer
              anchor={"left"}
              open={state["left"]}
              onClose={toggleDrawer("left", false)}
              onOpen={toggleDrawer("left", true)}
            >
              {list("left")}
            </SwipeableDrawer>
          </div>
        </div>
      </header>
      {/* <div className="account-type">
        <span>{props.account}</span>
      </div> */}
    </div>
  );
};

export default SuperAdminNav;
