import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = props => (
  <div className="not-found-container">
    <h1 className="not-found-title">404</h1>
    <h2 className="not-found-text">Sidan kan inte hittas.</h2>
    <p>Klicka <Link to="/search">här</Link> för att komma till startsidan.</p>
  </div>
)

export default NotFoundPage;