import React, { useState } from "react";
import StandardOffer from '../components/Offer/StandardOffer';
import ExpressOffer from '../components/Offer/ExpressOffer';

const OfferPage = props => {

  const [type, setType] = useState('Express');

  return(
    <>
    {type === 'Express'
    ? <ExpressOffer {...props} />
    : <StandardOffer {...props} />}
    </>
  )

}
  
export default OfferPage;
